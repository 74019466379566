// ***************************************************************  All Power BI Dashboards  ***************************************************************

export const dashboards = {
  // Territory Design
  territoryDesign: {
    JHLegacyRegion: {
      deployment2024: {
        reportId: "9a50c7ff-c069-4ff8-8cd9-0eec666759b4",
      },
    },
  },
};
