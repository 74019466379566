import { TbUser, TbUserEdit } from "react-icons/tb";
import { VscKey } from "react-icons/vsc";

export const account = [
  {
    id: 1,
    title: "Your Profile",
    url: "/account/view-profile",
    icon: [<TbUser />, <TbUserEdit />],
  },
  {
    id: 2,
    title: "Change Password",
    url: "/account/change-password",
    icon: [<VscKey />],
  },
];
