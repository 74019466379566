import React from "react";

const GuidingPrinciples = () => {
  return (
    <section className="flex flex-col gap-5 text-sm">
      <h2 className="font-bold text-xl">
        Territory Design Guiding Principles
      </h2>
      <div className="p-5 bg-blue-200 rounded-lg flex gap-12">
        <h2 className="font-bold text-lg w-44">
          Focus of Targeted Support <br /> (Spinnaker - JH Team)
        </h2>
        <ol className="list-decimal pl-5 flex flex-col gap-2">
          <li>
            <span className="font-bold">Operating Model Assessment: </span>{" "}
            Assess baseline of current territories and sales operating model to
            preserve strengths while addressing shortfalls/gaps
            <ul className="list-[square] pl-5 flex flex-col gap-2 mt-2">
              <li>
                Identify constraints and flag changeable vs not changeable over
                time vs. changeable immediately (e.g., treatment of national
                accounts)
              </li>
            </ul>
          </li>
          <li>
            <span className="font-bold">Territory Comparability: </span> Design
            territories that are reasonably comparable in size across several
            metrics
            <ul className="list-[square] pl-5 flex flex-col gap-2 mt-2">
              <li>
                <span className="underline underline-offset-2">FIRST</span> by
                future sales opportunity
                <ul className="list-[circle] pl-5 flex flex-col gap-2 mt-2">
                  <li>
                    Historical sales plus incremental sales opportunity (start
                    with consumer, then add channel)
                  </li>
                  <li>
                    Market size for relevant consumer attributes, product mix,
                    and current wallet share
                  </li>
                  <li>
                    Breadth and depth opportunity for expanding and leveraging
                    channel partners
                  </li>
                </ul>
              </li>
              <li>
                <span className="underline underline-offset-2">THEN</span>{" "}
                optimize for secondary attributes
                <ul className="list-[circle] pl-5 flex flex-col gap-2 mt-2">
                  <li>
                    Wholesaler assignments and responsibilities, visit
                    frequency, and expectations (implications for geographic
                    coverage)
                  </li>
                  <li>
                    Mix of established and underserved markets, adjust for
                    competitive intensity, avoid splitting states as much as
                    possible
                  </li>
                  <li>
                    Any adjustments for crediting (e.g., National Accounts,
                    BGA/IMO vs. PPGA and others) and implications/tradeoffs for
                    comp
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <span className="font-bold">Align Recruiting & Staffing: </span>{" "}
            Link medium-term sales goals and staffing requirements to develop
            interim recruiting targets
            <ul className="list-[square] pl-5 flex flex-col gap-2 mt-2">
              <li>
                Staffing (required vs. current) by territory and by roles with
                clear division of labor and productivity targets (see #4 below)
              </li>
              <li>
                Support roles such as internal desk, account management, etc.
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div className="p-5 bg-orange-100 rounded-lg flex gap-12">
        <h2 className="font-bold text-lg w-44">
          For JH Leadership Consideration
        </h2>
        <ol className="list-decimal pl-5 flex flex-col gap-2" start={4}>
          <li>
            <span className="font-bold">Productivity Targets: </span> Determine
            breakeven production requirements vs. deployment costs
            <ul className="list-[square] pl-5 flex flex-col gap-2 mt-2">
              <li>
                Develop scenarios for increases in average productivity targets
                (allow for ramp-up, onboarding/training/tools, etc.)
              </li>
            </ul>
          </li>
          <li>
            <span className="font-bold">
              Greater Adoption of Best Practices:{" "}
            </span>{" "}
            Apply best practices and benchmarks in production, activities,
            spans/layers, and division of labor
          </li>
          <li>
            <span className="font-bold">Talent Assessment: </span> Be clear on
            strengths/gaps in current talent; design to maximize sales potential
            and recruit/train to achieve that potential
          </li>
          <li>
            <span className="font-bold">Other Support Implications: </span>{" "}
            Ancillary and downstream staffing and support requirements (e.g.,
            marketing spend)
          </li>
        </ol>
      </div>
      <h2 className="font-bold text-xl mt-5">
        Territory Design Assumptions & Selection Criteria
      </h2>
      <div className="p-5 bg-gray-100 rounded-lg">
        <h2 className="font-bold text-lg">
          Management assumptions for territory redesign
        </h2>
        <ol className="list-[square] pl-5 flex flex-col gap-2 py-4">
          <li>
            House accounts (M Financial, Crump, Alternative) and Simple Term are
            out of scope for territory redesign
          </li>
          <li>
            Sales credited at agent and/or firm/BGA level; large producer groups
            (e.g., NFP) will be credited at top-level only for BGA submissions,
            else credited at local firm level
          </li>
          <li>
            Of the total US life producers of ~1.4M agents; addressable universe
            of independent life producers is 283K agents with 2+ appointments
            with peer group carriers and/or John Hancock
          </li>
          <li>
            Use formal submissions to ascertain firm/producer activity; informal
            submissions are out of scope
          </li>
          <li>
            Total market sales of $15B across Term, UL, IUL, VUL/VL, and Whole
            Life products
            <ul className="list-[circle] pl-5 flex flex-col gap-2 mt-2">
              <li>
                ~ $11B+ insurance sales to affluent and high net worth
                households with income greater than $100K
              </li>
            </ul>
          </li>
          <li>
            Established target premium threshold of $2,500 as cutoff between
            affluent ($100K-$200K income) and high net worth ($200K+) households
          </li>
          <li>
            Regional sales growth goal of $54M+ from $263M in 2023 to $317M in
            2027 (21%+ sales growth) by applying a weighted combination of:
            <ul className="list-[circle] pl-5 flex flex-col gap-2 mt-2">
              <li>
                <span className="font-bold">Wallet share</span> using 3-year
                average of JH APE sales divided by 3-year average of market APE
                sales in $100K+ households
              </li>
              <li>
                <span className="font-bold">Agent penetration</span> using
                producing agents divided by relevant market agents
              </li>
              <li>
                <span className="font-bold">Household penetration</span> using
                3-year cumulative JH policies sold divided by $100K+ households
              </li>
              <li>Growth floor and cap rates for each of the above ratios</li>
            </ul>
          </li>
          <li>
            Productivity targets by distribution roles: $30-35M for RVP and
            $3-5M for MDD
          </li>
          <li>
            Remain cost neutral or better vs. baseline ($8.5M tbc) OR cost
            structure within +/- 20% of baseline
          </li>
          Other factors: cost of sales / distribution allowance, persistency,
          other profit drivers – input into distribution compensation as
          appropriate
        </ol>
      </div>
    </section>
  );
};

export default GuidingPrinciples;
