import React from "react";
import { ImArrowRight } from "react-icons/im";

const hypotheses = [
  "IMOs will continue to acquire BGAs but exert tighter integration discipline than before (P&C analogy)",
  "Sales growth will come primarily from HNW segment, remainder from affluent segment",
  "RVP/NAVP roles should be able to manage higher productivity than current average",
  'RVP/NAVP focus on relationship management while in-market wholesalers focus on identifying and activating local "producers"',
  "POS channel drives more flow-like business while BGA/PPGA sells complex, high premium product",
  "Other?",
];

const implications = [
  [
    "Sales accountability balance may shift from BGA to IMO",
    "Align distribution roles and firm assignments",
  ],
  [
    "Continue deepening current relationships with BGAs and IMOs",
    "Develop new relationships with local producers and firms via in-market wholesalers with internal support",
  ],
  [
    "Fewer or no increase in senior roles to achieve growth goals ($X-$YM sales per RVP/NAVP)",
  ],
  [
    "RVP/NAVP does not need to be geographically proximate, while in-market wholesalers need to be local",
    "Internal support to provide support to both RVP/NAVP and in-market wholesalers",
  ],
  [
    "RVP should align to BGA/PPGA while junior wholesaler aligns to POS to drive high volume small transaction sales",
  ],
];

const scenarios = [
  "Super Regions",
  "Smaller Regions",
  "Channel Assignments",
  "Product / Consumer",
  "B2B (NDA)",
  "Other?",
];

const descriptions = [
  "Streamline regions with fewer territories and RVPs and NAVP for relationship management and activation depth",
  "Deploy wholesalers in concentrated high-value geographies with focus on marketing to channel partners in local markets",
  "Consolidate RVP and NAVP role for all channel relationship management and wholesale activities regardless of geography",
  "Wholesaler roles as product/solutions experts (e.g. “trusted advisor” and “solutions provider”), focused on marketing to channel partners targeting relevant consumers",
  "Assessment in-flight",
];

const whatYouHaveToBelieve = [
  [
    "RVPs can meet higher productivity targets",
    "Distribution allowance can support multiple wholesalers to drive flow-like business (high volume, low premium) with smaller partners",
  ],
  [
    "Channel partner needs regardless of size or complexity",
    "Distribution allowance supports managerial / coach mentor layer",
  ],
  [
    "Presumes extensive travel and ability to find sales and marketing skillset in a single person",
  ],
  [
    "Channel partners willing to engage with multiple, relevant JH experts based on solution need",
  ],
];

const TerritoryDesignScenarios = () => {
  return (
    // <section className="flex flex-col gap-10 text-sm">
    //   {/* Preliminary Hypotheses with Implications for Territory Design */}
    //   <div className="">
    //     <h2 className="font-bold text-lg">
    //       Preliminary Hypotheses with Implications for Territory Design
    //     </h2>
    //     {/* Table */}
    //     <div className="w-full mt-5">
    //       <table className="text-left">
    //         <thead>
    //           <tr>
    //             <th className="w-1/2 px-4 bg-tertiary text-white">
    //               Hypothesis
    //             </th>
    //             <th className="px-5">
    //               <ImArrowRight className="text-green-600 text-5xl" />
    //             </th>
    //             <th className="w-1/2 px-4 bg-primary text-white">
    //               Implications for Territory Design
    //             </th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {hypotheses.map((item, id) => (
    //             <tr key={id}>
    //               <td className="p-2 border border-r-0">
    //                 {id + 1 + ") " + item}
    //               </td>
    //               <td className="p-2" rowSpan={1}></td>
    //               <td className="p-2 border border-l-0">
    //                 <ul className="list-disc ml-4">
    //                   {implications[id]?.map((implication, index) => (
    //                     <li>{implication}</li>
    //                   ))}
    //                 </ul>
    //               </td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    //   {/* Distribution Structure Options: Select 2 for Scenario Development */}
    //   <div className="w-full">
    //     <h2 className="font-bold text-lg">
    //       Distribution Structure Options: Select 2 for Scenario Development
    //     </h2>
    //     {/* Table */}
    //     <div className="w-full mt-5">
    //       <table className="text-left w-full">
    //         <thead>
    //           <tr className="">
    //             <th className="w-[10%] pb-2">Scenario</th>
    //             <th className=""></th>
    //             <th className="w-[44%] pb-2">Description</th>
    //             <th className=""></th>
    //             <th className="w-[44%] pb-2">What you have to believe</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {scenarios.map((scenario, id) => (
    //             <>
    //               <tr key={id}>
    //                 <td className="p-2 h-20 text-white bg-tertiary text-base font-bold">
    //                   {id + 1 + ". " + scenario}
    //                 </td>
    //                 <td className=""></td>
    //                 <td className="p-2 bg-blue-200">{descriptions[id]}</td>
    //                 <td className=""></td>
    //                 <td className="p-2 bg-blue-200">
    //                   <ul className="list-disc ml-4">
    //                     {whatYouHaveToBelieve[id]?.map((item, index) => (
    //                       <li>{item}</li>
    //                     ))}
    //                   </ul>
    //                 </td>
    //               </tr>
    //               <tr className="h-4"></tr>
    //             </>
    //           ))}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    // </section>
    <iframe
      src="https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/john_hancock/blue-heron/Project+Blue+Heron+Scenario+vF.pdf"
      frameborder="0"
      className="w-full h-[85vh]"
    ></iframe>
  );
};

export default TerritoryDesignScenarios;
