// ***************************************************************   All Icons Import  ***************************************************************
import { dashboards } from "./dashboards";
import { GoHomeFill } from "react-icons/go";
import { GiHeron } from "react-icons/gi";
import { FaCompassDrafting, FaMapLocation } from "react-icons/fa6";
import { CgNotes } from "react-icons/cg";
import { FaGlobeAmericas } from "react-icons/fa";
import { LuCircuitBoard } from "react-icons/lu";
import { SiOpenstreetmap } from "react-icons/si";

// ***************************************************************   All Industries  ***************************************************************
export const deployment2024 = {
  title: "2024 Deployment",
  slug: "/2024-deployment",
  name: "deployment2024",
};

// ***************************************************************   All Solutions  ***************************************************************
// Territory Deisgn
export const territoryDesign = {
  title: "Blue Heron 2024",
  slug: "/blue-heron-2024",
  // slug: "#",
  icon: <GiHeron />,
  deployments: [deployment2024],
  deployment2024: [
    {
      title: "Current Territory Factbase",
      slug: "/current-factbase",
      icon: <FaGlobeAmericas />,
      dashboards: { ...dashboards.territoryDesign.JHLegacyRegion },
      description:
        "Factbase combining external and internal data with assessment of opportunities for greater alignment with sales growth goals",
    },
    {
      title: "Guiding Principles",
      slug: "/guiding-principles",
      // slug: "#",
      icon: <CgNotes />,
      description:
        "Guiding principles, related metrics, and assumptions as input for developing territory redesign scenarios",
    },
    {
      title: "Territory Design Scenarios",
      slug: "/territory-design-scenarios",
      // slug: "#",
      icon: <LuCircuitBoard />,
      description:
        "Territory design structure options and comparison / tradeoffs for JH leadership review",
    },
    {
      title: "Territory Design Tool",
      slug: "/territory-design-tool",
      icon: <FaCompassDrafting />,
      description:
      "Dynamic, interactive territory visualization and design tool",
    },
    {
      title: "Competitor Territories",
      slug: "/competitor-territories",
      icon: <SiOpenstreetmap />,
      description: "Overview of distribution structure and territory models for John Hancock's competitors",
    },
  ],
};

// Breadcrumbs
export const breadCrumbs = (item) => [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: item.title,
    url: item.slug,
  },
];

// ***************************************************************  Solutions in Home  ***************************************************************
export const home = [territoryDesign];

// ***************************************************************   Menu  ***************************************************************
export const menu = [
  {
    title: "Home",
    slug: "/home",
    icon: <GoHomeFill />,
  },
  ...home,
];
