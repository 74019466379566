let timeout;

// click event to open modal
export const onClickOpenEvent = (e, index, setStoredData, setElement) => {
  // setElement(null);
  clearTimeout(timeout);
  const rect = e.currentTarget.getBoundingClientRect();
  timeout = setTimeout(() => {
    setElement({
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
      bottom: rect.bottom + window.scrollY,
      width: rect.width,
      height: rect.height,
    });
    setStoredData(index);
  }, 500);
};

// click event to close modal
export const onClickCloseEvent = (setStoredData, setElement) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    setElement(null);
    setStoredData(null);
  }, 400);
};

// React Select change color
export const colourStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#0000c1" : "inherit",
    "&:hover": {
      backgroundColor: state.isSelected ? "#0000c1" : "#F3F4F6",
    },
  }),
  control: (base) => ({
    ...base,
    boxShadow: "none",
    border: "1px solid #ccc",
    "&:hover": {
      border: "1px solid #ccc",
    },
  }),
};
