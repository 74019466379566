import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { Link, useNavigate } from "react-router-dom";
import { PowerBiLoginRequest } from "./authConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
const video =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/auth/spinnaker-to-microsoft-dashboard.mp4";

const AuthPath = () => {
  const {
    saveBeforePathPBI,
    setIsPowerBIAuth,
    setPbiAccessToken,
    pbiAccessToken,
  } = useContext(UserContext);
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { instance } = useMsal();

  // Power BI login user
  const powerBILogin = async () => {
    instance
      .loginPopup(PowerBiLoginRequest)
      .then((res) => {
        console.log("On Login: ", res);
        setPbiAccessToken(res.accessToken);
        // sets auth globally
        localStorage.setItem("token_info", JSON.stringify(res));
        setIsPowerBIAuth(true);
        navigate(saveBeforePathPBI);
      })
      .catch((err) => {
        console.log("On Login Error\n: ", err);
      });
  };

  useEffect(() => {
    if (isAuthenticated && pbiAccessToken) {
      navigate("/home");
    } else {
      powerBILogin();
    }
  }, []);

  return (
    <div className="fixed top-0 z-50 left-0 h-screen w-screen bg-white font-semibold">
      <div className="flex px-2 justify-between items-end font-medium">
        <Link
          to={saveBeforePathPBI}
          className="hover:underline underline-offset-2"
        >
          Go Back
        </Link>
        <p>This is a secure login page</p>
      </div>
      <video
        src={video}
        className="outline-none"
        playsInline
        autoPlay
        muted
        loop
        disablePictureInPicture
      ></video>
    </div>
  );
};

export default AuthPath;
