import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import { account } from "../../data/account";
import ChangePassword from "../../components/account/ChangePassword";
import ViewProfile from "../../components/account/ViewProfile";
import { UserContext } from "../../context/userContext";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const Account = () => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle, loggedInUserData, userActivityTracker } = useContext(UserContext);

  useEffect(() => {
    account.map((item) => {
      if (item.url.split("/")[item.url.split("/").length - 1] === slug) {
        setPageData(item);
        return setIsSlugCorrect(true);
      } else {
        return null;
      }
    });
  }, [slug]);

  const crumbs = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Account",
      url: "/account/view-profile",
    },
    {
      title: pageData?.title,
    },
  ];

  useEffect(() => {
    setPageTitle(loggedInUserData.fname + "'s Account Settings");
    userActivityTracker("User visited thier profile", loggedInUserData.fname + "'s Account Settings");
  }, []);

  return (
    <>
      {isSlugCorrect && pageData ? (
        <>
          <section className="p-5 pt-0 w-full md:min-h-[30rem] h-full">
            <Breadcrumbs title={pageData.title} crumbs={crumbs} />
            <div className="my-5 flex gap-5 w-full h-full justify-center">
              <div className="w-full md:w-fit min-h-full p-3 rounded-lg md:border md:shadow-md bg-white relative">
                {pageData.title === "Your Profile" && <ViewProfile />}
                {pageData.title === "Change Password" && <ChangePassword />}
              </div>
            </div>
            {/* Powered By Spinnaker */}
            <div className="fixed hidden md:flex flex-col gap-10 right-5 bottom-5">
              <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
                <p className="text-[10px] 2xl:text-xs">Powered by</p>
                <img
                  src={spinnakerLogo}
                  alt="powered by spinnaker analytics"
                  className="w-28"
                />
              </div>
            </div>
          </section>
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Account;
