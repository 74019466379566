import React from "react";

const CompetitorTerritory = () => {
  return (
    <iframe
      src="https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/john_hancock/blue-heron/Competitor+Territory.pdf"
      frameborder="0"
      className="w-full h-[85vh]"
    ></iframe>
  );
};

export default CompetitorTerritory;
