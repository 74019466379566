import React from "react";
import Menu from "./Menu";
import Options from "./Options";
// import AskSAGE from "../bot/AskSAGE";

const Header = () => {
  return (
    <>
      <header>
        <div className="fixed w-full z-[5] flex justify-between gap-2 px-5 py-3 bg-white border-b items-center">
          <div className="w-full h-1 absolute bg-tertiary top-0 left-0"></div>
          <Menu />
          <Options />
        </div>
        <div className="px-5 py-3 pt-[3.5rem] pl-16 mb-2 w-full"></div>
        {/* Ask SAGE */}
        {/* <AskSAGE /> */}
      </header>
    </>
  );
};

export default Header;
