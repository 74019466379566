import {
  CurrencyCompactFormatter,
  NumberCompactFormatter,
  NumberFormatter,
  NumFormatterAllM,
  NumFormatterAllM2,
  NumFormatterK,
  PercentFormatter1,
  PercentFormatter2,
} from "./Utils";

export const drawingObject = (draw) => {
  return {
    polygon: draw === "polygon",
    // rectangle: draw === "rectangle",
    rectangle: draw === "polygon",
    circle: draw === "circle",
    marker: draw === "marker",
    polyline: draw === "polyline",
    circlemarker: draw === "circlemarker",
  };
};

// Drawing Modes
export const drawingModes = [
  {
    title: "Rectangle",
    type: "rectangle",
    icon: "",
    default: true,
  },
  {
    title: "Circle",
    type: "circle",
    icon: "",
    default: true,
  },
  {
    title: "Marker",
    type: "marker",
    icon: "",
    default: true,
  },
  {
    title: "Free Form",
    type: "polygon",
    icon: "",
    default: true,
  },
  {
    title: "Free Form line",
    type: "polyline",
    icon: "",
    default: true,
  },
  {
    title: "Circle Marker",
    type: "circlemarker",
    icon: "",
    default: true,
  },
];

export const products = [
  "All",
  "IUL",
  "UL",
  "VUL",
  "Term Life",
  "Whole Life",
  "Other",
];

export const metric = [
  "Sales Opportunity",
  "Market Sales",
  "JH APE Sales",
  "APE Share",
];

// function to create options out of arrays for react select
export const createOptionsForGradient = (items) => {
  let array = [];
  let parts = "";
  for (let i = 0; i < items.length; i++) {
    if (items[i].includes("&") === true) {
      parts = items[i].split(" & ").join(" and ");
    } else if (items[i].includes("'") === true) {
      parts = items[i].split("'").join("");
    } else {
      parts = items[i];
    }
    const object = {
      label: items[i] === "APE Share" ? "Sales Share" : items[i],
      value: parts,
    };
    array.push(object);
  }
  return array;
};

// "Sales Opportunity",
export const calculateMedian = (values) => {
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);
  if (values.length % 2) return values[half];
  return (values[half - 1] + values[half]) / 2.0;
};

export const calculatePercentile = (values, percentile) => {
  values.sort((a, b) => a - b);
  const index = Math.ceil((percentile / 100) * values.length) - 1;
  return values[index];
};

export const initialOptions = [
  "Market Sales",
  "JH APE Sales",
  "APE Share",
  "Relevant Market Agents",
  "JH Producing Agents",
  "Agent Share",
  "Households",
  "JH Sold Policies",
  "Policy Share",
  "JH Regional Consumer Sales",
  "Total Sales Opty",
  "Sales Growth %",
  // "JH Target Sales",
  // "Avg Household Income",
  // "Incremental Sales Opty",
];

export const mapTooltipColumns = [
  "Market Sales",
  "JH Regional Consumer Sales",
  "Total Sales Opty",
  "Sales Growth %",
];

export const tableAttributeOptions = [
  {
    id: 1,
    label: "Market Sales",
    value: "Market Sales",
    tooltipContent:
      "Relevant market size of Term, Whole, UL, IUL, and VL/VUL sales premium amongst households with annual income greater than $100K",
  },
  {
    id: 2,
    label: "JH APE Sales",
    value: "JH APE Sales",
    tooltipContent:
      "Average John Hancock APE sales by consumer location from 2021 to 2023",
  },
  {
    id: 3,
    label: "Sales Share",
    value: "APE Share",
    tooltipContent: "Ratio of JH APE Sales to Market Sales",
  },

  {
    id: 4,
    label: "Market Agents",
    value: "Relevant Market Agents",
    tooltipContent:
      "Relevant market size of independent agents appointed with 2 or more competitor carriers amongst John Hancock's peer group",
  },
  {
    id: 5,
    label: "JH Agents",
    value: "JH Producing Agents",
    tooltipContent:
      "Distinct count of John Hancock appointed agents that sold at least one policy between 2021 and 2023",
  },
  {
    id: 6,
    label: "Agent Share",
    value: "Agent Share",
    tooltipContent: "Ratio of JH Agents to Market Agents",
  },
  {
    id: 7,
    label: "Market Households",
    value: "Households",
    tooltipContent:
      "Relevant market size of households with annual income greater than $100K+",
  },
  {
    id: 8,
    label: "JH 3-Year Policies",
    value: "JH Sold Policies",
    tooltipContent:
      "Total count of John Hancock sold policies from 2021 to 2023",
  },
  {
    id: 9,
    label: "Household Share",
    value: "Policy Share",
    tooltipContent: "Ratio of JH 3-Year Policies to Market Households",
  },
  {
    id: 10,
    label: "JH Regional Target Sales",
    value: "JH Regional Consumer Sales",
    tooltipContent:
      "Average John Hancock target sales by consumer location from 2021 to 2023, excluding national (Crump, M) and house accounts (FINFIT, Allstate)",
  },
  {
    id: 11,
    label: "Regional Sales Opportunity",
    value: "Total Sales Opty",
    tooltipContent:
      "Forward-looking sales potential calculated using a combination of market metrics, JH metrics, and wallet share ratios ",
  },
  {
    id: 12,
    label: "Incr. Sales Growth %",
    value: "Sales Growth %",
    tooltipContent:
      "Ratio of incremental sales growth from Regional Sales Opportunity to JH Regional Target Sales",
  },
  // {
  //   id: 13,
  //   label: "JH Target Sales (Crediting)",
  //   value: "JH Credited Sales",
  //   tooltipContent:
  //     "2021-23 average John Hancock target sales premium based on crediting location",
  // },
  // {
  //   id: 14,
  //   label: "JH Target Sales",
  //   value: "JH Target Sales",
  //   tooltipContent:
  //     "2021-23 average John Hancock target sales premium based on consumer location",
  // },
  // {
  //   id: 15,
  //   label: "$100K+ Avg HH Income",
  //   value: "Avg Household Income",
  //   tooltipContent:
  //     "Average income for households with annual income greater than $100K",
  // },
  // {
  //   id: 16,
  //   label: "Incremental Sales Opportunity",
  //   value: "Incremental Sales Opty",
  //   tooltipContent:
  //     "Incremental sales opportunity on top of JH regional consumer sales",
  // },
];

// "Market Sales", "JH APE Sales", "APE Share"

export const attributeFormatter = {
  "Market Sales": NumFormatterAllM,
  "JH APE Sales": NumFormatterAllM,
  "APE Share": PercentFormatter1,
  Households: NumFormatterAllM2,
  "Avg Household Income": NumFormatterK,
  "Relevant Market Agents": NumberCompactFormatter,
  "JH Producing Agents": NumberCompactFormatter,
  "Agent Share": PercentFormatter1,
  "JH Credited Sales": NumFormatterAllM,
  "JH Target Sales": NumFormatterAllM,
  "JH Regional Consumer Sales": NumFormatterAllM,
  "JH Sold Policies": NumberCompactFormatter,
  "Policy Share": PercentFormatter2,
  "Incremental Sales Opty": NumFormatterAllM,
  "Total Sales Opty": NumFormatterAllM,
  "Sales Growth %": PercentFormatter1,
};

export const stateLevelAttributeFormatter = {
  "Market Sales": CurrencyCompactFormatter,
  "JH APE Sales": CurrencyCompactFormatter,
  "APE Share": PercentFormatter1,
  Households: NumberCompactFormatter,
  "Avg Household Income": NumFormatterK,
  "Relevant Market Agents": NumberCompactFormatter,
  "JH Producing Agents": NumberCompactFormatter,
  "Agent Share": PercentFormatter1,
  "JH Credited Sales": CurrencyCompactFormatter,
  "JH Target Sales": CurrencyCompactFormatter,
  "JH Regional Consumer Sales": CurrencyCompactFormatter,
  "JH Sold Policies": NumberFormatter,
  "Policy Share": PercentFormatter2,
  "Incremental Sales Opty": CurrencyCompactFormatter,
  "Total Sales Opty": CurrencyCompactFormatter,
  "Sales Growth %": PercentFormatter1,
};

export const attributeColors = {
  "Market Sales": "#ED7D31",
  "JH APE Sales": "#002060",
  "APE Share": "#059212",
  Households: "#ED7D31",
  "Avg Household Income": "#ED7D31",
  "Relevant Market Agents": "#ED7D31",
  "JH Producing Agents": "#002060",
  "Agent Share": "#059212",
  "JH Credited Sales": "#002060",
  "JH Target Sales": "#002060",
  "JH Regional Consumer Sales": "#002060",
  "JH Sold Policies": "#002060",
  "Policy Share": "#059212",
  "Incremental Sales Opty": "#A020F0",
  "Total Sales Opty": "#A020F0",
  "Sales Growth %": "#A020F0",
};

// export const sortTableValue = {
//   "Market Sales" : true,
//   "JH APE Sales" : true,
//   "APE Share": true,
//   "Relevant Market Agents" : true,
//   "JH Producing Agents" : true,
// }

// function to shift states
export const translateCoordinates = (
  coordinates,
  translateX,
  translateY,
  scaleX,
  scaleY
) => {
  if (Array.isArray(coordinates[0])) {
    return coordinates.map((coord) =>
      translateCoordinates(coord, translateX, translateY, scaleX, scaleY)
    );
  } else {
    return [
      coordinates[0] * scaleX + translateX,
      coordinates[1] * scaleY + translateY,
    ];
  }
};

export const reduceGeoJson = (geoJson, col) => {
  if (col === "APE Share") {
    return (
      geoJson.reduce((sum, obj) => sum + obj["JH APE Sales"], 0) /
      geoJson.reduce((sum, obj) => sum + obj["Market Sales"], 0)
    );
  }
  return geoJson.reduce((sum, obj) => sum + obj[col], 0);
};

export const calculateTotalAvgHouseHold = (geoData, mode = "") => {
  let totalIncome = 0;
  let totalTaxReturn = 0;

  if (mode === "csv" || mode === "rest") {
    geoData.forEach((object) => {
      totalIncome += object.properties["Total Income"];
      totalTaxReturn += object.properties["Households"];
    });

    return totalIncome / totalTaxReturn;
  }

  geoData.forEach((feature) => {
    feature.geoJson.forEach((object) => {
      totalIncome += object.properties["Total Income"];
      totalTaxReturn += object.properties["Households"];
    });
  });

  return totalIncome / totalTaxReturn;
};

export const calculateAgentShare = (data) => {
  const agentShare =
    data.reduce(
      (sum, object) => sum + object.properties.Agents["JH Producing Agents"],
      0
    ) /
    data.reduce(
      (sum, object) => sum + object.properties.Agents["Relevant Market Agents"],
      0
    );

  return agentShare;
};

export const calculatePolicyShare = (data) => {
  const policyShare =
    data.reduce(
      (sum, object) => sum + object.properties["JH Sold Policies"],
      0
    ) / data.reduce((sum, object) => sum + object.properties["Households"], 0);

  return policyShare;
};

export const calculateSalesGrowth = (data) => {
  const salesRatio =
    data.reduce(
      (sum, object) => sum + object.properties["Total Sales Opty"],
      0
    ) /
    data.reduce(
      (sum, object) => sum + object.properties["JH Regional Consumer Sales"],
      0
    );

  const salesGrowth = salesRatio - 1;

  return salesGrowth;
};
