import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/userContext";
import { home } from "../data/data";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const HomePage = () => {
  const { setPageTitle, userActivityTracker } = useContext(UserContext);

  useEffect(() => {
    setPageTitle(null);
    userActivityTracker("Page changed", "Home page");
  }, []);

  return (
    <section className="flex flex-col justify-center items-center p-5 pt-0 w-full h-full">
      <h1 className="md:text-xl lg:text-2xl font-bold text-center mt-2 md:mt-20 text-primary">
        Welcome to John Hancock Life Distribution Design Tool!
      </h1>
      <div className="w-full my-5 md:my-10 pb-10 relative flex items-end justify-center gap-20 transition-all duration-300 ease-in-out">
        <div
          className={`lg:px-10  flex flex-wrap items-center justify-center gap-5 transition-all duration-300 ease-in-out w-full`}
        >
          {home.map((module, index) => (
            <Link
              to={module.slug}
              key={index}
              className="w-full max-w-xs h-full p-10 flex flex-col items-center text-primary justify-between gap-2 relative hover:text-white border hover:border-primary bg-primary bg-opacity-5 hover:bg-opacity-100 shadow-xl rounded-2xl"
            >
              <span className="p-2 rounded-full text-5xl">{module.icon}</span>
              <h2 className="font-semibold text-base lg:text-lg">
                {module.title}
              </h2>
            </Link>
          ))}
        </div>
      </div>
      {/* Powered By Spinnaker */}
      <div className="fixed hidden md:flex flex-col gap-10 right-5 bottom-5">
        <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </div>
    </section>
  );
};

export default HomePage;
