import { BrowserCacheLocation } from "@azure/msal-browser";


const tenantId = process.env.REACT_APP_TENANTID;
const clientId = process.env.REACT_APP_CLIENTID;
const clientSecret = process.env.REACT_APP_CLIENTSECRET;

// console.log(process.env, tenantId);

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: "https://login.microsoftonline.com/" + tenantId,
    clientSecret: clientSecret,
    redirect: "/",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  }
};

export const PowerBiPermissionScopes = [
  "https://analysis.windows.net/powerbi/api/Dashboard.Read.All",
  "https://analysis.windows.net/powerbi/api/Dataset.Read.All",
  "https://analysis.windows.net/powerbi/api/Report.ReadWrite.All",
  "https://analysis.windows.net/powerbi/api/Group.Read.All",
  "https://analysis.windows.net/powerbi/api/Workspace.ReadWrite.All",
  "https://analysis.windows.net/powerbi/api/Content.Create",
];

export const PowerBiLoginRequest = {
  scopes: PowerBiPermissionScopes,
};
